/*
Neon Lights Text
By JavaScript Kit (http://javascriptkit.com)
For this script, TOS, and 100s more DHTML scripts,
Visit http://www.dynamicdrive.com
*/
var message = " sBiznes zawiera wszystkie elementy niezbędne dla Twojego biznesu "
var neonbasecolor = "silver"
var neontextcolor = "darkblue"
var flashspeed = 100 //in milliseconds

///No need to edit below this line/////

var n = 0;
var textHtml = '';
textHtml += '<font color="' + neonbasecolor + '">';

for (m = 0; m < message.length; m++) {
    textHtml += '<span id="neonlight' + m + '">' + message.charAt(m) + '</span>';
}
textHtml += '</font>';
$('#neonlight').html(textHtml);

function crossref(number) {
    var crossobj = document.all ? eval("document.all.neonlight" + number) : document.getElementById("neonlight" + number)
    return crossobj;
}

function neon() {

    //Change all letters to base color
    if (n == 0) {
        for (m = 0; m < message.length; m++) {
            //eval("document.all.neonlight"+m).style.color=neonbasecolor
            crossref(m).style.color = neonbasecolor;            
        }
    }

    //cycle through and change individual letters to neon color
    crossref(n).style.color = neontextcolor;

    if (n < message.length - 1) {
        n++
    } else {
        n = 0
        clearInterval(flashing)
        setTimeout("beginneon()", 1500)
        return
    }
}

function beginneon() {
    if (document.all || document.getElementById)
        flashing = setInterval(neon, flashspeed)
}
beginneon();