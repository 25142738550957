$(function () {
    
    function onScroll(event) {
        var scrollPos = $(document).scrollTop();

        $('.js-main-menu-link').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));

            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('.js-main-menu-link').removeClass("active");
                currLink.addClass("active");
            }
            else {
                currLink.removeClass("active");
            }
        });
    }

    
    let $footer = $('.js-footer');
    let $footerContent = $footer.find('.js-footer-content-wrap');
    // let $footerBar = $footer.find('.js-footer-bar');

    let $content = $('#content');
    let $menu = $('#menu');

    new Swiper('#partners-slider', {
        slidesPerView: 3,
        paginationClickable: true,
        spaceBetween: 20,
        autoplay: 2000,
        autoplayDisableOnInteraction: false,
        loop: true,
        breakpoints: {
            // when window width is <= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            // when window width is <= 640px
            768: {
              slidesPerView: 2,
              spaceBetween: 20
            }
          }
    });

    $(document).on("scroll", onScroll);

    //smoothscroll
    $('.js-main-menu-link').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        
        $('.js-main-menu-link').each(function () {
            $(this).removeClass('active');
        })
        $(this).addClass('active');
      
        var target = this.hash,
            menu = target;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top+2
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });

    function setContentTopOffset() {
        $content.css({
            marginTop: $menu.height() + 'px',
            // marginBottom: $footer.height() + 'px'
        });
    }

    $(window).load(function() {
        setContentTopOffset();
        scaleCircle();
        showCircle();
    });

    $(window).resize(function() {
        setContentTopOffset();
        scaleCircle();
    });

    var scaleCircle = function () {
        var $circle = $('.js-circle-big');
        var windowWidth = $(window).width();
        var scale = (windowWidth - 60) / $circle.width();

        if (scale < 1) {
            $circle.css('transform', 'scale(' + scale + ')');
        } else {
            $circle.css('transform', 'scale(1)');

        }
    }

    var showCircle = (function() {
        var executed = false;
        return function () {
            var circleTopOffset = $('#functionality').offset().top - $('.js-circle-big').height();
            // console.log($(window).scrollTop(), circleTopOffset);
            if ($(window).scrollTop() > circleTopOffset) 
            // if (elementInViewport($('#functionality')[0])) 
            {
                if (!executed) 
                {
                    executed = true;
                    $('.js-sector').removeClass('preload');
                    $('.js-circle-center').addClass('loaded');
                }
            }
        }
    })();

    
    $(window).scroll(function () {
        showCircle();
    });

    // Footer toggle
    $('.js-footer-collapse').click(function(e) {
        
        e.preventDefault();
        $footerContent.slideToggle(300, function() {
            $footer.toggleClass('collapsed');
        });
    });

    // Sector text click
    $('.js-sector-text').click(function(e) {
        e.preventDefault();
        let $target = $($(e.target).attr('href'));

        $('.js-sector-info:not(.hide)').fadeOut(300, function() {
            $('.js-sector-info').addClass('hide');
            // $target.fadeIn(300, () => {
            $target.show().removeClass('hide')
            // });
        });
        
        
        $('.js-sector-text').closest('.js-sector').removeClass('active');
        $(e.target).closest('.js-sector').addClass('active');
    });
});
